@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-balham.css';

.ag-header-cell-text {
  @apply whitespace-normal font-bold;
}

.ag-header-group-cell-label {
  @apply font-bold;
}

.web-border-left-2px,
.border-left-2px {
  @apply border-0 !border-l-2 border-solid !border-l-grey-default;
}

.web-border-left-1px,
.border-left-1px {
  @apply border-0 !border-l border-solid !border-l-grey-default;
}

.border-top-0 {
  @apply !border-t-0;
}

.ag-row-level-1 .actions {
  @apply pr-4;
}

.ag-row-level-2 .actions {
  @apply pr-6;
}

.ag-row-level-3 .actions {
  @apply pr-8;
}

.ag-cell-type-number,
.ag-cell-type-date,
.ag-cell-type-price,
.ag-cell-type-integer,
.ag-cell-type-report-price,
.ag-cell-type-number-without-zero-formatting,
.ag-cell-type-float {
  @apply text-right;
}

.ag-cell-type-text {
  @apply text-left;
}

.border-right-1px {
  @apply border-0 !border-r border-solid !border-r-grey-default;
}

.white-background {
  @apply bg-white;
}

.gray-lightest-background {
  background-color: rgba(0, 0, 0, 0.03);
}

.white-color {
  @apply text-white;
}

.grey-light-background {
  @apply bg-grey-light;
}

.grey-lighter-background {
  @apply bg-grey-lighter;
}

.ag-pinned-left-header,
.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  @apply border-r-2 border-r-grey-default;
}

.ag-header-cell,
.ag-header-group-cell {
  @apply !px-[5px];
}

.ag-header-row-column-filter {
  @apply bg-white;
}

div.ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper > input.ag-input-field-input {
  @apply bg-white;
}

.align-to-center {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
}

.align-to-center-and-center {
  @apply align-to-center;
  @apply items-center;
}

.align-to-center-and-right {
  @apply align-to-center;
  @apply items-end;
}

.ag-cell-wrapper.ag-row-group {
  @apply items-center;

  @apply !outline-none;
  @apply !border-transparent;
}

.ag-disabled .ag-body-viewport {
  @apply opacity-60;
  @apply cursor-default;
  @apply pointer-events-none;
}

.ag-editable {
  @apply cursor-pointer;
  box-shadow: 0px 0px 0px 0.3px grey inset;
}

.ag-editable:hover {
  box-shadow: 0px 0px 0px 0.6px #d04a02 inset;
  @apply cursor-pointer;
}

.ag-preserve-new-lines {
  @apply whitespace-pre;
}

.ag-wrap-words {
  @apply whitespace-pre-wrap;
}

.align-right {
  @apply text-right;
}

.fake-group .ag-cell-wrapper {
  margin-left: 28px;
}

.manage-cost-center .ag-cell-wrapper.ag-cell-expandable.ag-row-group.ag-row-group-indent-2 {
  padding-left: 0;
}

.bg-highlighted {
  background-color: #f2dcd2;
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: #ffffff;
}

.ag-row-selected {
  background-color: rgba(208, 74, 2, 0.2) !important;
}

.ag-checkbox-input-wrapper.ag-checked {
  background-color: #d04a02 !important;
  font-size: 18px !important;
  border: none;
}

.ag-checkbox-input-wrapper.ag-checked::after {
  color: white !important;
}

.ag-row-checkbox-disable {
  .ag-checkbox-input-wrapper {
    pointer-events: none;
    opacity: 0.5;
  }
}

.ag-checkbox-input {
  cursor: pointer;
}
